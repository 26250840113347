import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Input } from "antd";

import Filters from "components/common/filters";
import Export from "components/common/export";

import {
    getBetHistory,
    getBetHistoryTotals,
    setBetHistoryFilters
} from 'store/actions/dashboard/betHistory.action';
import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";
import { getManagerAvailableCashiers } from "store/actions/dashboard/cashiers.action";

import useFormat from "hooks/useFormat";

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';
import { BET_STATE, BET_TYPE } from 'constants/bets.constants';
import {BETSLIP_FILTER_TYPE, DATE_PICKER_RANGES, PROJECT_PROVIDER_TYPE} from 'constants/common.constants';
import ApiUrls from "constants/api.constants";

import managerAvailableBetshopType from "types/managerAvailableBetshop.type";
import autoSuggestionType from "types/autoSuggestion.type";
import userInfoType from 'types/userInfo.type';

let timer = null;

/** Bet History Filters Component */
const BetHistoryFilters = ({
    setBetHistoryFilters,
    getBetHistory,
    getBetHistoryTotals,
    filters,
    getManagerAvailableBetshops,
    getManagerAvailableCashiers,
    availableBetshops,
    availableCashiers,
    columns,
    canPlaceAnonymousBets
}) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const [opened, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState(filters.betSlipId)

    const mappedFilters = {
        from: filters.from,
        to: filters.to,
        userNameOrId: filters.userNameOrId,
        betSlipFilterType: filters.betSlipFilterType,
        period: filters.period,
        providertype: filters.providertype,
        type: filters.type,
        betShopNameOrId: filters.betShopNameOrId,
        status: filters.status,
        playerUserNameOrId: !canPlaceAnonymousBets ? filters.playerUserNameOrId : undefined,
        filterType: 1
    }

    const dateTypeOptions = useMemo(() => {
        return [
            {
                title: t('common.betDate'),
                value: BETSLIP_FILTER_TYPE.BET_TIME
            },
            {
                title: t('common.calculationDate'),
                value: BETSLIP_FILTER_TYPE.CALCULATION_TIME
            },
            {
                title: t('common.payoutDate'),
                value: BETSLIP_FILTER_TYPE.PAYOUT_TIME
            },
        ]
    }, []);

    /** Load available betshops and cashiers for betshop manager */
    useEffect(() => {
        if (getUser()?.role === USER_ROLE.MANAGER) {
            getManagerAvailableBetshops();
            getManagerAvailableCashiers();
        }
    }, [])

    /** Function, handler for betslipId search input
         * @function
         * @param {string} value - the field value
         * @memberOf BetHistoryFilters
    */
    const handleInputChange = value => {

        const reg = new RegExp('^[0-9]*$');
        if (!isNaN(value) && reg.test(value) || value === '') {
            setSearchValue(value);
            clearTimeout(timer);
            timer = setTimeout(() => {
                if(!value || value.length > 2){
                    setBetHistoryFilters({
                        betSlipId: value && value.length > 2 ? value : ""
                    })
                    setTimeout(() => {
                        getBetHistory()
                        getBetHistoryTotals();
                    }, 0)
                }
            }, 300)
        }
    }

    return (
        <div className='rt--filters-header rt--mt-32 rt--pb-16 rt--mb-30'>
            <div className='rt--flex rt--justify-between rt--align-center'>
                <div className='rt--flex rt--align-center rt--filters-header-title'>
                    <span className='rt--title rt--font-big rt--font-regular'>
                        {
                            t("common.betHistory")
                        }
                    </span>
                </div>

                <div className='rt--flex'>
                    <div className='rt--filters-header-search'>
                        <Input
                            placeholder={t("common.searchByBetslipId")}
                            onChange={e => handleInputChange(e.target.value)}
                            value={searchValue}
                            maxLength={12}
                        />
                        <i className='icon-search rt--font-bigest'></i>
                    </div>

                    <div className="rt--flex rt--align-center">
                        <Export
                            filters={mappedFilters}
                            title={t("common.betHistory")}
                            columns={columns}
                            url={ApiUrls.EXPORT_BET_HISTORY}
                            tableName={t("common.betHistory")}
                        />

                        <button
                            className='rt--button rt--button-secondary rt--ml-16'
                            onClick={() => setOpened(!opened)}
                        >
                            <span className='rt--flex rt--justify-center rt--align-center'>
                                <i className='icon-filter rt--font-bigest'></i>
                                <span className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <Filters
                loadFn={() => {
                    getBetHistory();
                    getBetHistoryTotals();
                }}
                setFiltersFn={setBetHistoryFilters}
                filters={mappedFilters}
                useDateWithTime={true}
                controls={[
                    {
                        title: t('common.dateType'),
                        name: "betSlipFilterType",
                        type: "SELECT",
                        small: true,
                        items: dateTypeOptions
                    },
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            { value: DATE_PICKER_RANGES.TODAY, title: t('common.today') },
                            { value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday') },
                            { value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth') },
                            { value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth') },
                            { value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months') }
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                    },
                    ...(!canPlaceAnonymousBets ? [
                        {
                            title: t('common.playerUsernameOrId'),
                            placeholder: t('common.playerUsernameOrId'),
                            name: "playerUserNameOrId",
                            type: "INPUT",
                        }
                    ] : []),
                    {
                        title: t('common.status'),
                        name: "status",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            { value: BET_STATE.PENDING, title: t("common.pending") },
                            { value: BET_STATE.WON, title: t("common.won") },
                            { value: BET_STATE.LOST, title: t("common.lost") },
                            { value: BET_STATE.CANCELLED, title: t("common.cancelled") },
                            { value: BET_STATE.REJECTED_BY_PROVIDER, title: t("common.rejectedByProvider") },
                            { value: BET_STATE.PAID_OUT, title: t("common.paidOut") },
                        ],
                        small: true,
                    },
                    {
                        title: t('common.provider'),
                        name: "providertype",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            { value: PROJECT_PROVIDER_TYPE.SPORTBOOK, title: t("common.sportsBook") },
                            { value: PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, title: t("common.virtualSport") },
                        ],
                        small: true,
                    },
                    {
                        title: t('common.betType'),
                        name: "type",
                        type: "SELECT",
                        items: [
                            { value: "", title: t('common.all') },
                            { value: BET_TYPE.SINGLE, title: t("common.single") },
                            { value: BET_TYPE.MULTI, title: t("common.multi") },
                            { value: BET_TYPE.SYSTEM, title: t("common.system") },
                        ],
                        small: true,
                    },
                    ...(
                        getUser()?.role === USER_ROLE.MANAGER ? [
                            {
                                title: t('common.betshop'),
                                name: "betShopNameOrId",
                                type: "SELECT",
                                items: [
                                    { value: null, title: t('common.all') },
                                    ...availableBetshops.map(b => ({
                                        value: b.id, title: b.name
                                    }))
                                ],
                                onChange: (value, updateForm) => {
                                    setBetHistoryFilters({
                                        userNameOrId: ""
                                    })
                                    updateForm("userNameOrId", "")
                                    getManagerAvailableCashiers(value)
                                },
                                small: true,
                            },
                            {
                                title: t('common.cashier'),
                                name: "cashierUserNameOrId",
                                type: "SELECT",
                                items: [
                                    { value: "", title: t('common.all') },
                                    ...availableCashiers.map(b => ({
                                        value: b.id, title: b.name
                                    }))
                                ],
                                small: true,
                            },
                        ] : []
                    )
                ]}
                visible={opened}
            />
        </div>

    )
}

/** BetHistoryFilters propTypes
    * PropTypes
*/
BetHistoryFilters.propTypes = {
    /** Redux action to get bet history */
    getBetHistory: PropTypes.func,
    /** Redux action to get bet history totals */
    getBetHistoryTotals: PropTypes.func,
    /** Redux action to set bet history filters */
    setBetHistoryFilters: PropTypes.func,
    /** Redux action to get available betshops for manager */
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux action to get available cashiers for manager */
    getManagerAvailableCashiers: PropTypes.func,
    /** Redux state property, bet history filters */
    filters: PropTypes.object,
    /** Redux state property, available betshops for manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Redux state property, available cashiers for manager */
    availableCashiers: PropTypes.arrayOf(autoSuggestionType),
    /** Redux state property, current user info */
	userInfo: userInfoType,
    /** Table columns */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /** Column title */
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Column property of data */
        dataIndex: PropTypes.string,
        /** Column content render function */
        render: PropTypes.func,
        /** Is column sortable */
        sorter: PropTypes.bool,
        /** Is Numeric Field */
        isNumeric: PropTypes.bool
    })),
    /** Prop, represents can user place anonymous bets */
    canPlaceAnonymousBets: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getBetHistory: () => {
            dispatch(getBetHistory());
        },

        getBetHistoryTotals: () => {
            dispatch(getBetHistoryTotals());
        },

        setBetHistoryFilters: filters => {
            dispatch(setBetHistoryFilters(filters));
        },

        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        },

        getManagerAvailableCashiers: id => {
            dispatch(getManagerAvailableCashiers(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        filters: state.betHistory.filters,
        availableBetshops: state.managerBetshops.availableBetshops,
        availableCashiers: state.cashiers.availableCashiers
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetHistoryFilters);
