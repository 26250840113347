import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux'

import { getUserInfo, setBalance, setLimit } from 'store/actions/dashboard/userInfo.action';

import SignalRUtils from 'utils/signalR';
import { getUser, logout, refreshToken } from 'utils/auth';
import LocalStorageUtils from 'utils/localStorage';

import { USER_TYPE, USER_ROLE } from 'constants/user.constants';
import { SIGNALR_CONNECTION_TYPES, WALLET_TYPE } from "constants/common.constants";

import userInfoType from 'types/userInfo.type';

let interval = null;

/** Global Scripts Component */
const Global = ({
    setBalance,
    setLimit,
    getUserInfo,
    userInfo
}) => {

    /** Load user info */
    useEffect(() => {
        getUserInfo();
    }, [])

    /** Handle SignalR */
    useEffect(() => {
        if (userInfo.betShopId) {
            SignalRUtils.buildConnections(handleSignalREvents);
        }

        return () => {
            clearInterval(interval);
            SignalRUtils.getConnections().forEach(connection => {
                connection.getConnection().off("UpdateBalanceAndLimit");
                connection.getConnection().off("UpdateLimit");
                connection.getConnection().off("WalletChange");
                connection.getConnection().off("Logout");
                connection.getConnection().off("RefreshToken");
            })
            SignalRUtils.removeConnections();
        }

    }, [userInfo.betShopId])

    /** Function to subscribe and handle signalR events
     * @function
     * @description checks to allow only numeric characters
     * @memberOf Global
     */
    const handleSignalREvents = (connection, signalRType) => {

        if (signalRType === SIGNALR_CONNECTION_TYPES.ADMIN) {
            interval = setInterval(() => {
                const sessionId = getUser()?.sessionId ?? null;
                const role = getUser()?.role ?? null;
                if (connection.getConnection()._connectionState === "Connected") {
                    connection.getConnection().invoke(
                        "UpdateSession", 
                        sessionId, 
                        userInfo?.cashier?.id ?? null, 
                        role === USER_ROLE.CASHIER ? USER_TYPE.CASHIER: USER_TYPE.BETSHOP_MANAGER
                    );
                }
            }, 60000 * 10)
        }

        connection.getConnection().on("UpdateBalanceAndLimit", (data) => {
            const { balance } = JSON.parse(data);
            const { limit } = JSON.parse(data);
            setBalance(Number(balance));
            setLimit(Number(limit));
        });

        connection.getConnection().on("UpdateLimit", (data) => {
            const { limit } = JSON.parse(data);
            setLimit(Number(limit));
        });

        connection.getConnection().on("WalletChange", (data) => {
            const { amount, type } = JSON.parse(data);
            if(type === WALLET_TYPE.BALANCE){
                setBalance(amount);
            } else if(type === WALLET_TYPE.LIMIT){
                setLimit(amount);
            }
        });

        connection.getConnection().on("Logout", () => {
            const userName = userInfo.cashier.userName;
            const betShopData = LocalStorageUtils.get('betshopData') || {};
            delete betShopData[userName];
            LocalStorageUtils.set('betshopData', betShopData);
            logout();
        });

        connection.getConnection().on('RefreshToken', () => {
            const token = getUser()?.refreshToken ?? null;
            const userName = getUser()?.userName ?? null;
            refreshToken(token, userName);
        });
    };

    return <Fragment />
}

/** Global propTypes
    * PropTypes
*/
Global.propTypes = {
    /** Redux action to load current user info */
    getUserInfo: PropTypes.func,
    /** Redux action to set current user balance */
    setBalance: PropTypes.func,
    /** Redux action to set current user limit */
    setLimit: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

const mapDispatchToProps = dispatch => (
    {
        getUserInfo: () => {
            dispatch(getUserInfo())
        },
        setBalance: data => {
            dispatch(setBalance(data))
        },
        setLimit: data => {
            dispatch(setLimit(data))
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Global);