import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { PRODUCT_TYPE } from "constants/common.constants";

import {
    SET_BET_HISTORY_ACTION_BEFORE,
    SET_BET_HISTORY_ACTION_FINISH,
    SET_BET_HISTORY,
    SET_BET_HISTORY_SORTING,
    SET_BET_HISTORY_FILTERS,
    SET_BET_HISTORY_TOTALS_ACTION_BEFORE,
    SET_BET_HISTORY_TOTALS_ACTION_FINISH,
    SET_BET_HISTORY_TOTALS
} from "store/actionTypes";
import { BET_STATE } from "constants/bets.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const setBetHistoryActionBefore = () => ({
    type: SET_BET_HISTORY_ACTION_BEFORE,
});

const setBetHistoryActionFinished = () => ({
    type: SET_BET_HISTORY_ACTION_FINISH,
});


const setBetHistory = bets => ({
    type: SET_BET_HISTORY,
    payload: { bets },
});

const setBetHistoryTotalsActionBefore = () => ({
    type: SET_BET_HISTORY_TOTALS_ACTION_BEFORE,
});

const setBetHistoryTotalsActionFinished = () => ({
    type: SET_BET_HISTORY_TOTALS_ACTION_FINISH,
});

const setBetHistoryTotals = totals => ({
    type: SET_BET_HISTORY_TOTALS,
    payload: { totals },
})

export const setBetHistorySorting = sorting => ({
    type: SET_BET_HISTORY_SORTING,
    payload: { sorting },
});

export const setBetHistoryFilters = filters => ({
    type: SET_BET_HISTORY_FILTERS,
    payload: { filters },
});

export const getBetHistory = () => {
    return (dispatch, getState) => {
        dispatch(setBetHistoryActionBefore());

        const params = {
            ...getState().betHistory.sorting,
            ...getState().betHistory.filters,
            productType : PRODUCT_TYPE.RETAIL,
            filterType: 1
        }
        if (params.status === BET_STATE.PAID_OUT) {
                params.IsPaidout = true;
                params.status = "";
        }

        return axios({
            url: ApiUrls.GET_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setBetHistory(bets));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}

export const getBetHistoryTotals = () => {
    return (dispatch, getState) => {
        dispatch(setBetHistoryTotalsActionBefore());

        const userRole = getUser()?.role;
        const currencyCode = getState().profile.userInfo?.currencies?.[0].code.toLowerCase() ?? null;

        const params = {
            ...getState().betHistory.sorting,
            ...getState().betHistory.filters,
            productType : PRODUCT_TYPE.RETAIL,
            filterType: 1
        }

        if (params.status === BET_STATE.PAID_OUT) {
            params.IsPaidout = true;
            params.status = "";
        }

        return axios({
            url: ApiUrls.GET_BET_HISTORY_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                if (userRole === USER_ROLE.CASHIER && currencyCode) {
                    dispatch(setBetHistoryTotals({[currencyCode]: bets[currencyCode]}));
                } else {
                    dispatch(setBetHistoryTotals(bets));
                }

                dispatch(setBetHistoryTotalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryTotalsActionFinished());
            });
    }
}
