import { PROJECT_PROVIDER_TYPE } from "constants/common.constants";
import {
    SET_PROVIDER,
    LAUNCH_PROVIDER,
    LAUNCH_PROVIDER_BEFORE,
    LAUNCH_PROVIDER_FINISH,
    RESET_PROVIDERS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PROVIDER:
            return {
                ...state,
                provider: payload
            }
        case LAUNCH_PROVIDER_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case LAUNCH_PROVIDER_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case LAUNCH_PROVIDER:
            return {
                ...state,
                providers: {
                    ...state.providers,
                    [payload.id]: payload.data
                }
            };
        case RESET_PROVIDERS:
            return {
                ...state,
                providers: {
                    [PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS]: {},
                    [PROJECT_PROVIDER_TYPE.SPORTBOOK]: {}
                }
            };
        default:
            return state;
    }
};
